import OrderAdditionalPrice from "../../entities/OrderAdditionalPriceRequest";
import AddPaymentRequest from "../payment/AddPaymentRequest";
import MiscellanyOrderCategoryReguest from "./MiscellanyOrderCategoryReguest";

class MiscellanyOrderRequest {
    public clientId: string;
    public agencyId?: string;
    public userId?: string;
    public miscellanyCategories: MiscellanyOrderCategoryReguest[];
    public payments: AddPaymentRequest[];
    public additionalPrices?: OrderAdditionalPrice[];
    public description?: string;
    public total: number;

    constructor(
        clientId: string,
        miscellanyCategories: MiscellanyOrderCategoryReguest[],
        payments: AddPaymentRequest[],
        total: number,
        agencyId?: string,
        userId?: string,
        additionalPrices?: OrderAdditionalPrice[],
        description?: string
    ) {
        this.clientId = clientId;
        this.agencyId = agencyId;
        this.userId = userId;
        this.miscellanyCategories = miscellanyCategories;
        this.payments = payments;
        this.additionalPrices = additionalPrices;
        this.description = description;
        this.total = total;
    }
}

export default MiscellanyOrderRequest;