import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import MoneyExchangeRate from "../../../entities/productOrder/moneyDelivery/MoneyExchangeRate";
import {MoneyExchangeService} from "../../../service/moneyDelivery/MoneyExchangeRateService";
import Currency from "../../../entities/Currency";

const ERROR_MAP = {
    change: ["ERROR_00_400_35", "ERROR_00_400_35"],
    currency: ["ERROR_00_500_52"]
}

class UpdateMoneyExchangeRatesStore {

    public agencyIds: string[];
    public moneyExchange: MoneyExchangeRate;
    public currency?: Currency;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public changeError?: string;
    public currencyError?: string;

    constructor(commonStore: CommonStore, agencyIds: string[]) {
        this.agencyIds = agencyIds;
        this.isModalOpen = false;
        this.moneyExchange = {} as MoneyExchangeRate;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.moneyExchange?.value) && !!(this.moneyExchange?.currency);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const response = await MoneyExchangeService.updateSeveral(this.agencyIds, this.moneyExchange);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.changeError = undefined;
        this.currencyError = undefined;
        if (error) {
            if (ERROR_MAP.change.includes(error)) {
                this.changeError = error;
            }
            if (ERROR_MAP.currency.includes(error)) {
                this.currencyError = error;
            }
        }

    }
}

export default UpdateMoneyExchangeRatesStore;