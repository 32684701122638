import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    clients_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    clients_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container: {},
    client_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: '40px 250px 150px 200px auto 300px 50px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    client_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    inactive: {
        borderLeft: `8px solid ${theme.palette.grey["300"]}`,
        backgroundColor: `${theme.palette.grey["300"]}8c`,
        opacity: '0.8'
    },
    menu_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    menu_container_small: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        "& > *": {
            marginLeft: '4px'
        }
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
        rowGap: 16,
        "& button": {
            justifySelf: "end"
        }
    },
    tools_container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px'
    },
    search_btn: {
        justifySelf: 'flex-end'
    },
    btn_header_container: {
        color: '#757575',
        fontSize: '13px',
        fontWeight: 800
    },
    margin_bottom_5px: {
        marginBottom: '5px'
    },
    options_container: {
        display: 'flex'
    },
    select_all_checkbox: {
        marginLeft: '15px',
        marginRight: '10px'
    },
}));

export {useStyles}