import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function showSuccessToast(text: string, autoClose: number = 10000) {
    toast.success(text, {
        position: "top-right",
        autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
}