import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'space-between',
            height: 'calc( 100vh - 250px)',
            maxHeight: 'calc( 100vh - 250px)'
        },
        category_resumme_container: {
            maxHeight: 'calc( 100vh - 503px)',
            overflow: 'auto'
        },
        description_container: {
            height: '50%'
        }
    }))
;

export {useStyles}