import React, { useCallback, useEffect } from 'react';
import PrintToDeliverButtonProperties from "./PrintToDeliverButtonProperties";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./PrintToDeliverButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import { Config } from '../../../util/Config';
import DocumentViewer from '../../../ui_components/DocumentViewer/DocumentViewer';
import PrintIcon from '@mui/icons-material/Print';

const PrintToDeliverButton: React.FC<PrintToDeliverButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderIds,
                                                                      service,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT,
                                                                            invoice
                                                                  }) => {
    
    const getInvoiceUrl = useCallback(() => {
        const ids = productOrderIds.map(id => `ids=${encodeURIComponent(id)}`).join("&");
        return Config.get("GET_PRODUCT_ORDERS_TO_DELIVER", { service, ids });
    }, [productOrderIds, service]);

    const classes = useStyles(showText);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [invoiceUrl, setInvoiceUrl] = React.useState(getInvoiceUrl());

    useEffect(() => {
        setInvoiceUrl(getInvoiceUrl());
    }, [productOrderIds, getInvoiceUrl]);

    const handleOnclick = async () => {
        setIsModalOpen(true);
        onClick();
    }

    const onCloseModal = () => {
        setIsModalOpen(false);
        if (onSave) {
            onSave();
        }
    }

    const text = 'Imprimir lista de entregas';
    const Icon = PrintIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    return <Observer>
    {() => (
        <>
            <SidePanel title={'Imprimir lista de entregas'}
                       onClose={onCloseModal} open={isModalOpen} className={classes.modal_container}>
                <div className={classes.view_invoice_container}>
                    {isModalOpen && <DocumentViewer url={invoiceUrl}/>}
                </div>
            </SidePanel>
            {showButton && <Btn/>}
            {!showButton && <div onClick={handleOnclick}>{children}</div>}
        </>
    )}
</Observer>
}

export default PrintToDeliverButton;
