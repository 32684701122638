import React from "react";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../Agency.style";
import clsx from "clsx";
import AgencyRowProperties from "./AgencyRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewAgencyButton from "../../../shared_components/Buttons/NewAgencyButton/NewAgencyButton";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ShowAddress from "../../../shared_components/Info/ShowAddress/ShowAddress";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import RemoveAgencyButton from "../../../shared_components/Buttons/RemoveAgencyButton/RemoveAgencyButton";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import AgencySalesOverviewButton
    from "../../../shared_components/Buttons/AgencySalesOverviewButton/AgencySalesOverviewButton";
import AgencyMoneyExchangeButton
    from "../../../shared_components/Buttons/AgencyMoneyExchangeButton/AgencyMoneyExchangeButton";
import AllowAccess from "../../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../../shared_components/Access/AllowAccess/Access";
import UpdatePaymentFeeButton from "../../../shared_components/Buttons/UpdatePaymentFeeButton/UpdatePaymentFeeButton";
import UpdateAgencyMainUserPasswordButton
    from "../../../shared_components/Buttons/UpdateAgencyMainUserPasswordButton/UpdateAgencyMainUserPasswordButton";
import DownloadMoneyDeliverySCVButton from "../../../shared_components/Buttons/DownloadMoneyDeliveryCSVButton/DownloadMoneyDeliverySCVButton";
import DownloadAgencyOrdersReportButton from "../../../shared_components/Buttons/DownloadAgencyOrdersReportButton/DownloadAgencyOrdersReportButton";
import CustomRangeRepository, { RangeName } from "../../../ui_components/DateTimeRageSelect/CustomRangeRepository";
import Checkbox from "../../../ui_components/Checkbox/Checkbox";

const AgencyRowMedium: React.FC<AgencyRowProperties> = ({
                                                            agency,
                                                            selected,
                                                            onAgencySelect,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const classes = useStyles();
    const i18n = useI18nContext();
    const selectedRange = RangeName.THIS_WEEK;
    const range = CustomRangeRepository.getRange(i18n, selectedRange);

    const agencySelect = () => {
        onAgencySelect(agency.id);
    }

    return (
        <div
            className={clsx(classes.client_info_container)}>
            <Checkbox text={''} onChange={agencySelect}
                            selected={selected}/>
            <ShowSellerBasicInfo autofill name={agency.name} email={agency.email} phone={agency.phone}/>
            <ShowDate label={i18n.translate("CREATED")} timestamp={agency.createdAt}/>
            <ShowAddress value={agency.address}/>
            <ShowText title={i18n.translate("SALE_PRICES")} text={agency.salePriceName ?? '-'}/>
            <div>
                <span className={classes.btn_header_container}>Reporte Semanal:</span>
                <DownloadMoneyDeliverySCVButton
                    from={range?.from?.valueOf()}
                    to={range?.to?.valueOf()}
                    agencyId={agency.id}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.SMALL}
                    text={"Descargar órdenes"}
                    className={classes.margin_bottom_5px}
                />
                <DownloadAgencyOrdersReportButton
                    type={ButtonType.SECONDARY}
                    showPreview={false}
                    agencyId={agency.id}
                    from={range?.from?.valueOf()}
                    to={range?.to?.valueOf()}
                    size={ButtonSize.SMALL}/>
            </div>
            <ExpandoMenu>
                <>
                    <NewAgencyButton type={ButtonType.SECONDARY} onSave={onUpdate} agencyId={agency.id}
                                     size={ButtonSize.SMALL} showText/>
                    <AllowAccess any={[Access.SET_AGENCY_MONEY_DELIVERY_EXCHANGE_RATE]}>
                        <AgencyMoneyExchangeButton size={ButtonSize.SMALL}
                                                   agencyId={agency.id} showText
                                                   type={ButtonType.SECONDARY}/>
                    </AllowAccess>
                    <AllowAccess any={[Access.UPDATE_PAYMENT_FEES]}>
                        <UpdatePaymentFeeButton size={ButtonSize.SMALL}
                                                agencyId={agency.id} showText
                                                type={ButtonType.SECONDARY}/>
                    </AllowAccess>
                    <AgencySalesOverviewButton size={ButtonSize.SMALL} type={ButtonType.SECONDARY}
                                               agency={agency}/>
                    <RemoveAgencyButton size={ButtonSize.SMALL} onSave={onUpdate} agency={agency}
                                        type={ButtonType.SECONDARY}/>
                    <UpdateAgencyMainUserPasswordButton size={ButtonSize.SMALL} type={ButtonType.SECONDARY}
                                                        agencyId={agency.id} agencyName={agency.name}/>
                </>
            </ExpandoMenu>
        </div>
    )
}

export default AgencyRowMedium;
