import { action, makeAutoObservable, runInAction } from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ArticleDelivery from "../../../entities/ArticleDelivery";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import Client from "../../../entities/Client";
import Agency from "../../../entities/Agency";
import Seller from "../../../entities/Seller";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import { AppStorage, AppStorageValues } from "../../../util/AppStorage";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyOrderService } from "../../../service/miscellany/MiscellanyOrderService";
import MiscellanyOrderRequest from "../../../model/miscellany/MiscellanyOrderRequest";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";

class NewMiscellanyOrderStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public commonStore: CommonStore;
    public response?: ArticleDelivery[]
    public errorKey?: string;

    public finishButtonLoading = false;

    public activeStep = 0;

    public description: string;

    // Miscellany Category
    public miscellanyCategories: MiscellanyCategory[];
    public selectedMiscellanyCategories: MiscellanyCategory[];
    public miscellanyCategoryFilter: string;
    public loadingMiscellanyCategories: boolean;

    // Client area
    public selectedClient?: Client;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;
    
    public observations?: string;

    // Additional prices
    public additionalPrices: OrderAdditionalPrice[];

    // Payments
    public payments: AddPaymentRequest[];
    public paymentCompleted: boolean;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.validating = false;
        this.description = '';
        this.miscellanyCategories = [];
        this.selectedMiscellanyCategories = [];
        this.miscellanyCategoryFilter = "";
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.loadingMiscellanyCategories = false;
        this.commonStore = commonStore;
        this.selectedAgency = AppStorage.get(AppStorageValues.SELECTED_AGENCY, undefined);
        this.selectedSeller = AppStorage.get(AppStorageValues.SELECTED_SELLER, undefined);
        makeAutoObservable(this);
    }

    public reset() {
        this.selectedMiscellanyCategories = [];
        this.selectedClient = undefined;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.activeStep = 0;
        this.observations = undefined;
    }

    @action
    public async openModal() {
        this.reset();
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public restart() {
        this.response = undefined;
    }

    // Contact area

    @action
    public setClient(client?: Client) {
        this.selectedClient = client;
    }

    // Miscellany Category area

    @action
    public async searchMiscellanyCategories(): Promise<void> {
        this.loadingMiscellanyCategories = true;
        const response = await MiscellanyCategoryService.get(1, 1000, this.miscellanyCategoryFilter, this.selectedAgency?.id);
        if (response.success) {
            runInAction(() => {
                this.miscellanyCategories = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.loadingMiscellanyCategories = false;
    }

    @action
    public async miscellanyCategoryAmountChange(miscellanyCategoryId: string, amount: number) {
        if (amount === 0) {
            this.selectedMiscellanyCategories = this.selectedMiscellanyCategories.filter(t => t.id !== miscellanyCategoryId);
            return;
        }
        const miscellanyCategory = this.miscellanyCategories!.find(t => t.id === miscellanyCategoryId);
        if (!miscellanyCategory) {
            return;
        }
        const selectedProductIndex = this.selectedMiscellanyCategories.findIndex(t => t.id === miscellanyCategoryId);
        if (selectedProductIndex >= 0) {
            this.selectedMiscellanyCategories[selectedProductIndex] = { ...miscellanyCategory, amount };
        } else {
            this.selectedMiscellanyCategories.push({ ...miscellanyCategory, amount });
        }
    }

    // Additional prices area

    @action
    public addAdditionalPrice(currency: string, amount: number, comment: string) {
        this.additionalPrices.push(new OrderAdditionalPrice(amount, comment, false));
    }

    @action
    public removeAdditionalPrice(additionalPrice: OrderAdditionalPrice) {
        this.additionalPrices = this.additionalPrices.filter(t => t.amount !== additionalPrice.amount && t.comment !== additionalPrice.comment);
    }

    public getAdditionalPriceTotal(): number {
        return this.additionalPrices.reduce((sum, current) => sum + current.amount, 0);
    }

    // Payment area

    public getServicePrice(): number {
        return this.selectedMiscellanyCategories?.reduce((total, obj) =>
            total + (obj.price * (obj.amount ?? 1)), 0) || 0;
    }

    public getTotalToPay(): number {
        return this.getServicePrice() + this.getAdditionalPriceTotal();
    }

    @action
    public setPayments(payments: AddPaymentRequest[], paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
    }

    @action
    public async createMiscellanyOrder() {
        if (!this.selectedClient) {
            await this.commonStore.showErrorToast('Debe seleccionar un cliente.');
            return;
        }
        if (!this.selectedMiscellanyCategories || this.selectedMiscellanyCategories.length === 0) {
            await this.commonStore.showErrorToast('Debe seleccionar alguna categoria.');
            return;
        }
        if (this.finishButtonLoading) {
            return;
        }
        this.finishButtonLoading = true;
        const response = await MiscellanyOrderService.create(
            new MiscellanyOrderRequest(
                this.selectedClient?.id,
                this.selectedMiscellanyCategories.map(category => {
                    return {
                        miscellanyCategoryId: category.id,
                        amount: category.amount ?? 1,
                        price: category.price
                    }
                }),
                this.payments,
                this.getTotalToPay(),
                this.selectedAgency?.id,
                this.selectedSeller?.id,
                this.additionalPrices,
                this.description
        ));
        if (response.success && response.data) {
            this.closeModal();
            window.location.href = '/miscellany-orders';
        }
        this.finishButtonLoading = false;
    }

}

export default NewMiscellanyOrderStore;