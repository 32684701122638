import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import ClientContact from "../../../entities/ClientContact";
import Currency from "../../../entities/Currency";
import MoneyDeliveryBudgetResponse from "../../../model/moneyDelivery/MoneyDeliveryBudgetResponse";
import {MoneyDeliveryService} from "../../../service/moneyDelivery/MoneyDeliveryService";
import MoneyDeliveryOrder from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryOrder";
import {ClientContactService} from "../../../service/ClientContactService";
import ContactBankCard from "../../../entities/ContactBankCard";
import Client from "../../../entities/Client";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import MoneyDeliveryOrderRequest from "../../../model/moneyDelivery/MoneyDeliveryOrderRequest";
import Agency from "../../../entities/Agency";
import Seller from "../../../entities/Seller";
import Access from "../../Access/AllowAccess/Access";
import UserStore from "../../../stores/UserStore";
import { AppStorage, AppStorageValues } from "../../../util/AppStorage";

const ERROR_MAP = {
    change: ["ERROR_00_400_36"]
}

class NewMoneyDeliveryStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public commonStore: CommonStore;
    public userStore: UserStore;
    public response?: MoneyDeliveryOrder[]
    public errorKey?: string;

    public amountToSendError?: string;
    public amountToReceiveError?: string;

    public finishButtonLoading = false;

    public activeStep = 0;
    public createAnother = false;

    // Remittances data

    // Contacts
    public selectedClient?: Client;
    public selectedContact?: ClientContact;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;
    public selectedContactBankCards?: ContactBankCard[];
    public selectedContactBankCardId?: string;

    public observations?: string;

    // Money remittance data
    public noBudgetData: boolean = true;

    // Defines if the budget data must by calculated in order to sended or receiver amount
    public isByAmountToSend: boolean = true;

    public amountToSend?: number;
    public amountToReceive?: number;
    public totalToPay?: number;
    public currency?: Currency = Currency.DOLLAR;

    // Contact bank card remittance data
    public isCardRemittance: boolean = false;
    public contactBankCard?: ContactBankCard;

    public budgetDetails?: MoneyDeliveryBudgetResponse;
    public budgetDetailsLoading: boolean = false;

    // Additional prices
    public isAdditionalPricesStepCompleted = false;
    public additionalPrices: OrderAdditionalPrice[];

    public payments: AddPaymentRequest[];
    public paymentCompleted: boolean;

    // Summary
    public possibleRepeatedDelivery: boolean = false;
    public possibleRepeatedDeliveriesOrders: string[] = [];


    public onSuccessCreate?: () => void;

    constructor(commonStore: CommonStore, userStore: UserStore, onSuccessCreate?: () => void) {
        this.isModalOpen = false;
        this.validating = false;
        this.additionalPrices = [];
        this.commonStore = commonStore;
        this.userStore = userStore;
        this.payments = [];
        this.paymentCompleted = false;
        this.selectedAgency = AppStorage.get(AppStorageValues.SELECTED_AGENCY, undefined);
        this.selectedSeller = AppStorage.get(AppStorageValues.SELECTED_SELLER, undefined);
        this.onSuccessCreate = onSuccessCreate;
        makeAutoObservable(this);
    }

    get enableFinish(): boolean {
        return !!this.selectedClient &&
                !!this.selectedContact &&
                !!this.amountToSend &&
                !!this.totalToPay &&
                !!this.amountToReceive;
    }

    public reset() {
        this.amountToSend = undefined;
        this.amountToReceive = undefined;
        this.totalToPay = undefined;
        this.isCardRemittance = false;
        this.currency = Currency.DOLLAR;
        this.selectedClient = undefined;
        this.selectedContact = undefined;
        this.selectedContactBankCards = [];
        this.selectedContactBankCardId = undefined;
        this.isCardRemittance = false;
        this.contactBankCard = undefined;
        this.budgetDetails = undefined;
        this.budgetDetailsLoading = false;
        this.isAdditionalPricesStepCompleted = false;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.activeStep = 0;
        this.observations = undefined;
    }

    public resetToCreateAnother() {
        this.amountToSend = undefined;
        this.amountToReceive = undefined;
        this.totalToPay = undefined;
        this.isCardRemittance = false;
        this.currency = Currency.DOLLAR;
        this.selectedContact = undefined;
        this.selectedContactBankCards = [];
        this.selectedContactBankCardId = undefined;
        this.isCardRemittance = false;
        this.contactBankCard = undefined;
        this.budgetDetails = undefined;
        this.budgetDetailsLoading = false;
        this.isAdditionalPricesStepCompleted = false;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.observations = undefined;
    }

    @action
    public async openModal() {
        this.reset();
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        if (this.onSuccessCreate) {
            this.onSuccessCreate();
        }
    }

    @action
    public restart() {
        this.response = undefined;
    }

    // Contact area

    @action
    public setClient(client?: Client) {
        this.selectedClient = client;
    }

    @action
    public setContact(contact?: ClientContact) {
        this.selectedContact = contact;
        this.selectedContactBankCardId = undefined;
        this.contactBankCard = undefined;
        this.selectedContactBankCards = [];
        this.loadSelectedContactBankCards();
    }

    loadSelectedContactBankCards() {
        ClientContactService.getBankCards(1, 100, this.selectedContact?.id).then(response => {
            if (response.success && response.data) {
                this.selectedContactBankCards = response.data.data;
            }
        })
    }

    @action
    public setObservations(observations?: string) {
        this.observations = observations;
    }

    // Money remittance data area

    @action
    public async getBudget(): Promise<void> {
        if (this.totalToPay) {
            await this.getBudgetDetailsFromAmountToPay()
        } else if (this.amountToSend) {
            await this.getBudgetDetailsFromAmountToSend()
        } else if (this.amountToReceive) {
            await this.getBudgetDetailsFromAmountToReceive()
        }
    }

    @action
    public async getBudgetDetailsFromAmountToSend(): Promise<void> {
        this.budgetDetails = undefined;
        this.budgetDetailsLoading = true;
        if (this.currency) {
            const response = await MoneyDeliveryService.getMoneyDeliveryBudget(this.currency, this.amountToSend, 0, 0, this.selectedAgency?.id);
            this.budgetDetailsLoading = false;
            if (response.success && response.data) {
                this.budgetDetails = response.data;
                this.noBudgetData = false;
                this.amountToReceive = this.budgetDetails.amountToReceive;
                this.totalToPay = this.budgetDetails.totalToPay;
            }
            this.commonStore.processErrors(response);
        } else {
            this.noBudgetData = true;
        }
    }

    @action
    public async getBudgetDetailsFromAmountToPay(): Promise<void> {
        this.budgetDetails = undefined;
        this.budgetDetailsLoading = true;
        if (this.currency) {
            const response = await MoneyDeliveryService.getMoneyDeliveryBudget(this.currency, 0, 0, this.totalToPay, this.selectedAgency?.id);
            this.budgetDetailsLoading = false;
            if (response.success && response.data) {
                this.budgetDetails = response.data;
                this.noBudgetData = false;
                this.amountToSend = this.budgetDetails.amountToSend;
                this.amountToReceive = this.budgetDetails.amountToReceive;
            }
            this.commonStore.processErrors(response);
        } else {
            this.noBudgetData = true;
        }
    }

    @action
    public async getBudgetDetailsFromAmountToReceive(): Promise<void> {
        this.budgetDetails = undefined;
        this.budgetDetailsLoading = true;
        if (this.currency) {
            const response = await MoneyDeliveryService.getMoneyDeliveryBudget(this.currency, 0, this.amountToReceive, 0, this.selectedAgency?.id);
            this.budgetDetailsLoading = false;
            if (response.success && response.data) {
                this.budgetDetails = response.data;
                this.noBudgetData = false;
                this.amountToSend = this.budgetDetails.amountToSend;
                this.totalToPay = this.budgetDetails.totalToPay;
            }
            this.commonStore.processErrors(response);
        } else {
            this.noBudgetData = true;
        }
    }

    // Additional prices area

    @action
    public setAdditionalPrices(additionalPrices: OrderAdditionalPrice[]) {
        this.additionalPrices = additionalPrices;
    }

    // ****************************

    @action
    public async createMoneyDelivery() {
        if (this.isCardRemittance && !this.contactBankCard) {
            await this.commonStore.showErrorToast('Debe seleccionar una tarjeta para el destinatário.');
            return;
        }
        if (!this.selectedContact) {
            await this.commonStore.showErrorToast('Debe seleccionar un contacto.');
            return;
        }
        if (!this.budgetDetails) {
            await this.commonStore.showErrorToast('Debe seleccionar los valores del envío.');
            return;
        }
        if (this.finishButtonLoading) {
            return;
        }
        this.finishButtonLoading = true;
        const request = new MoneyDeliveryOrderRequest(
            this.selectedContact!!.clientId,
            this.selectedContact!!.id,
            this.amountToSend || 0,
            this.amountToReceive || 0,
            this.totalToPay || 0,
            this.currency || Currency.DOLLAR,
            this.payments,
            this.additionalPrices,
            this.contactBankCard?.id,
            this.selectedAgency?.id,
            this.selectedSeller?.id,
            this.observations
        )
        const response = await MoneyDeliveryService.create(request);

        if (response.success && response.data) {
            if (!this.createAnother) {
                this.closeModal();
            } else {
                this.resetToCreateAnother();
                this.activeStep = 
                    this.userStore.hasAccess(Access.SEND_ORDERS_TO_AGENCIES) && this.userStore.hasAccess(Access.LIST_AGENCY) ?
                    1 : 0;
            }
        } else {
            await this.commonStore.showErrorToast("No fue posible crear el envío de dinero")
        }
        this.finishButtonLoading = false;
    }

    public getFinalTotalToPay(): number {
        const total = this.payments.reduce((partialSum, a) => partialSum + a.amount, 0);
        const totalFees = this.payments.reduce((partialSum, a) => partialSum + a.getExtraFee, 0);
        const additionalPrices = this.additionalPrices.reduce((sum, current) => sum + current.amount, 0);
        return total + totalFees + additionalPrices;
    }

    public getServicePrice(): number {
        return this.budgetDetails?.totalToPay || 0;
    }

    public getTotalToPay(): number {
        return this.getServicePrice();
    }

    @action
    public setPayments(payments: AddPaymentRequest[], paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
    }

    // Resume area

    @action
    public async checkMoneyDeliveryPossiblyRepeated(): Promise<void> {
        this.possibleRepeatedDelivery = false;
        if (this.enableFinish) {
            const response = await MoneyDeliveryService.getPossiblyRepeated(
                this.selectedClient!.id,
                this.selectedContact!.id,
                this.currency!,
                this.amountToReceive!.toString(),
                this.contactBankCard?.id
            );
            this.possibleRepeatedDelivery = response != null && response.data != null && response.data.length > 0;
            this.possibleRepeatedDeliveriesOrders = response.data != null ? response.data.map(t => t.orderNumber) : [];
        }
    }

    processError(error?: string) {
        this.amountToReceiveError = undefined;
        this.amountToSendError = undefined;
        if (error) {
            if (ERROR_MAP.change.includes(error)) {
                this.amountToSendError = error;
            }
            if (ERROR_MAP.change.includes(error)) {
                this.amountToReceiveError = error;
            }
        }
    }

}

export default NewMoneyDeliveryStore;