import Phone from "../../entities/Phone";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import MiscellanyOrderCategoryResponse from "./MiscellanyOrderCategoryResponse";

class MiscellanyOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public agencyName: string;
    public clientName: string;
    public clientPhone: Phone | null;
    public userId: string;
    public userName: string;
    public totalToPay: number;
    public createdAt: number;
    public status: ProductOrderStatus;
    public miscellanyCategories: MiscellanyOrderCategoryResponse[];
    public description: string;

    constructor(
        id: string,
        orderNumber: string,
        agencyId: string,
        agencyName: string,
        clientId: string,
        clientName: string,
        clientPhone: Phone | null,
        userId: string,
        userName: string,
        totalToPay: number,
        status: ProductOrderStatus,
        createdAt: number,
        miscellanyCategories: MiscellanyOrderCategoryResponse[],
        description: string
    ) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.agencyName = agencyName;
        this.clientId = clientId;
        this.clientName = clientName;
        this.clientPhone = clientPhone;
        this.userId = userId;
        this.userName = userName;
        this.totalToPay = totalToPay;
        this.status = status;
        this.createdAt = createdAt;
        this.miscellanyCategories = miscellanyCategories;
        this.description = description;
    }

}

export default MiscellanyOrderResponse;