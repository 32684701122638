import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import Steppers from '../../../ui_components/Stepper/Steppers';
import StepperOption from '../../../ui_components/Stepper/StepperOption';
import AdditionalPrices from './AdditionalPrice/AdditionalPrices/AdditionalPrices';
import NewMiscellanyOrderButtonProperties from './NewMiscellanyOrderButtonProperties';
import SidePanel from '../../../ui_components/SidePanel/SidePanel';
import Access from '../../Access/AllowAccess/Access';
import { useUserContext } from '../../../contexts/UserContext';
import AgencySelect from './AgencySelect/AgencySelect';
import Payment from './Payment/Payment';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ClientSelect from './ClientSelect/ClientSelect';
import { useStyles } from './NewMiscellanyOrderButton.style';
import NewMiscellanyOrderStore from './NewMiscellanyOrderStore';
import MiscellanyOrderResume from './Resume/MiscellanyOrderResume';
import MiscellanyCategorySelect from './MiscellanyCategorySelect/MiscellanyCategorySelect';


const NewMiscellanyOrderButton: React.FC<NewMiscellanyOrderButtonProperties> = ({
                                                                                                onClick = () => {
                                                                                                },
                                                                                                showIcon = true,
                                                                                                onSave = () => {
                                                                                                },
                                                                                                showText = true,
                                                                                                disabled,
                                                                                                className = '',
                                                                                                rounded = false,
                                                                                                size = ButtonSize.DEFAULT,
                                                                                                type = ButtonType.DEFAULT
                                                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewMiscellanyOrderStore(commonContext);
    const userStore = useUserContext();

    const handleOnclick = async () => {
        onClick();
        await store.openModal();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const isAgencySelected = (): boolean => {
        if (!store.selectedAgency) return true;
        return !!store.selectedSeller?.id
    }

    const isClientStepCompleted = (): boolean => {
        return store.selectedClient != null;
    }

    const text = showText ? 'Nueva Orden de Miscelánea' : "";
    const tooltip = !showText ? 'Nueva Orden de Miscelánea' : "";
    const btnIcon = showIcon ? <DashboardCustomizeIcon/> : React.Fragment;

    let index = 0;
    let steps: StepperOption[] = [];
    if (userStore.hasAccess(Access.SEND_ORDERS_TO_AGENCIES) && userStore.hasAccess(Access.LIST_AGENCY)) {
        steps.push(new StepperOption(index++, i18n.translate("SELECT_AGENCY"), isAgencySelected, <AgencySelect
            store={store}/>))
    }
    steps.push(new StepperOption(1, 'Categorias', () => true, <MiscellanyCategorySelect
            store={store}/>));
    steps.push(new StepperOption(1, i18n.translate("SELECT_CONTACT_PLACEHOLDER"), isClientStepCompleted, <ClientSelect
            store={store}/>));
    steps.push(new StepperOption(2, "Precios y tarifas", () => true, <AdditionalPrices
            store={store}/>, () => {}));
    steps.push(new StepperOption(index++, i18n.translate("PAYMENT"), () => {
        return true;
    }, <Payment
        store={store}/>));
    steps.push(new StepperOption(3, i18n.translate("RESUME"), () => {
            return false;
        }, <MiscellanyOrderResume store={store}/>));

    const handleFinish = () => {
        store.createMiscellanyOrder();
        if (onSave) {
            onSave();
        }
    };

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                className={className}
                onClick={handleOnclick}
                tooltip={tooltip}
                disabled={disabled}
                size={size}
                type={type}
                text={text}
                icon={btnIcon}
            />
        }
        return <Button
            className={className}
            onClick={handleOnclick}
            tooltip={tooltip}
            disabled={disabled}
            size={size}
            type={type}
            text={text}
            startIcon={btnIcon}
        />
    }

    return <Observer>
        {() => (
            <>
                <SidePanel title={'Nueva Orden de Miscelánea'} onClose={onCloseModal}
                           open={store.isModalOpen} className={classes.modal_container}>
                    {!store.response &&
                        <Steppers 
                            activeStep={store.activeStep}
                            onActiveStepChange={(value) => {
                                store.activeStep = value;
                            }}
                            steps={steps} containerClassName={classes.stepper_container}
                            handleFinish={handleFinish} finishButtonLoading={store.finishButtonLoading}/>}    
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewMiscellanyOrderButton;
