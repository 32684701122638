import React from 'react';
import ShowMiscellanyOrderCategoriesProperties from "./ShowMiscellanyOrderCategoriesProperties";
import {useStyles} from "./ShowMiscellanyOrderCategories.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import Currency from '../../../entities/Currency';

const ShowMiscellanyOrderCategories: React.FC<ShowMiscellanyOrderCategoriesProperties> = ({
                                                        valueToPay,
                                                        categories,
                                                        className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`${i18n.translate("TOTAL_TO_PAY")}:`}</div>
                    <div className={classes.value}>{valueToPay && CurrencyModule.format(valueToPay, Currency.DOLLAR)}</div>
                </div>
                <div className={classes.currency}>USD</div>
            </div>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Categorias (${categories.length}):`}</div>
                    <div className={classes.value}>
                        {categories.map((category, index) => (
                            <span>{category.miscellanyCategoryName} ({category.amount}){index < categories.length - 1 && <span>,</span>} </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ShowMiscellanyOrderCategories;