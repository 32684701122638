import React from 'react';
import AgencyRowProperties from "./AgencyRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import AgencyRowMedium from "./AgencyRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import AgencyRowSmall from "./AgencyRowSmall";


const AgencyRow: React.FC<AgencyRowProperties> = ({
                                                      agency,
                                                      selected,
                                                      onUpdate,
                                                      onAgencySelect,
                                                  }) => {
    return (
        <>
            <ViewSmall>
                <AgencyRowSmall selected={selected} onAgencySelect={onAgencySelect} onUpdate={onUpdate} agency={agency}/>
            </ViewSmall>
            <ViewMedium>
                <AgencyRowMedium selected={selected} onAgencySelect={onAgencySelect} onUpdate={onUpdate} agency={agency} />
            </ViewMedium>
            <ViewLarge>
                <AgencyRowMedium selected={selected} onAgencySelect={onAgencySelect} onUpdate={onUpdate} agency={agency} />
            </ViewLarge>
        </>
    )
}

export default AgencyRow;
