import Request from "../../model/Request";
import Response from "../../model/Response";
import { RestClient } from "../../util/RestClient";
import { Config } from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import Phone from "../../entities/Phone";
import MiscellanyOrderRequest from "../../model/miscellany/MiscellanyOrderRequest";
import MiscellanyOrderResponse from "../../model/miscellany/MiscellanyOrderResponse";

export module MiscellanyOrderService {

  export async function get(
    page: number = 1,
    pageSize: number = 15,
    from: number = 0,
    to: number = 0,
    filter?: string,
    status?: string[],
    agencyId?: string
  ): Promise<Response<PagedResponse<MiscellanyOrderResponse>>> {
    const url = Config.get("GET_MISCELLANY_ORDER_URL", {
      page,
      pageSize,
      from,
      to,
      filter,
      status,
      agencyId,
    });
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const resultData = response.data?.data;
      const paginationData = response.data?.pagination;
      if (resultData && paginationData) {
        const data = resultData.map(
          (t: any) =>
            new MiscellanyOrderResponse(
              t.id,
              t.orderNumber,
              t.agencyId,
              t.agencyName,
              t.clientId,
              t.clientName,
              t.clientPhone
                ? new Phone(
                    t.clientPhone.number,
                    t.clientPhone.countryCode,
                    t.clientPhone.phoneType
                  )
                : null,
              t.userId,
              t.userName,
              t.totalToPay,
              t.status,
              t.createdAt,
              t.miscellanyCategories,
              t.description
            )
        );
        const pagination = new Pagination(
          paginationData.currentPage,
          paginationData.pageSize,
          paginationData.elementsCount,
          paginationData.pageCount
        );
        return new Response<PagedResponse<MiscellanyOrderResponse>>(
          true,
          { data, pagination },
          response.error
        );
      }
    }
    return new Response<PagedResponse<MiscellanyOrderResponse>>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function create(
    miscellanyOrderOrder: MiscellanyOrderRequest
  ): Promise<Response<any>> {
    const url = Config.get("CREATE_MISCELLANY_ORDER_URL");
    const request = new Request(url, miscellanyOrderOrder);
    return await RestClient.post(request);
  }


  export async function updateStatus(id: string, status: string, comment: string): Promise<Response<any>> {
    const url = Config.get("UPDATE_MISCELLANY_ORDER_STATUS_URL", {
        id,
        status
    });
    const request = new Request(url, { comment: comment ? comment : '' });
    return await RestClient.put(request);
  }

  export async function updateBulkStatus(ordersIds: string[], status: string, comment: string): Promise<Response<any>> {
    const url = Config.get("UPDATE_MISCELLANY_ORDERS_STATUS_URL", {
        status: status
    });
    const request = new Request(url, { ordersIds, comment: comment ? comment : '' });
    return await RestClient.put(request);
  }

  export async function remove(miscellanyOrderId: string): Promise<Response<any>> {
    const url = Config.get("REMOVE_MISCELLANY_ORDER_URL", {id: miscellanyOrderId});
    const request = new Request(url);
    return await RestClient.remove(request);
  }
}
