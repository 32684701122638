import React from 'react';
import {useStyles} from "./DescriptionSelect.style";
import {Observer} from "mobx-react-lite";
import { Box } from '@material-ui/core';
import DescriptionSelectProperties from './DescriptionSelectProperties';
import ObservationsInput from '../../../Inputs/ObservationsInput/ObservationsInput';
import ShowMiscellanyOrderCategoryItem from '../../../Info/ShowMiscellanyOrderCategoryItem/ShowMiscellanyOrderCategoryItem';

const DescriptionSelect: React.FC<DescriptionSelectProperties> = ({
                                                                          store
                                                                      }) => {
    const classes = useStyles();

    const onDescriptionChange = (newDescription: string) => {
        store.description = newDescription;
    }

    return (
        <Observer>
            {() => (
                <>
                    <Box className={classes.container}>
                        <div className={classes.category_resumme_container}>
                            {store.selectedMiscellanyCategories.map((el, i) => (
                                <ShowMiscellanyOrderCategoryItem
                                        key={i}
                                        id={el.id}
                                        title={el.name}
                                        text={el.description}
                                        price={el.price}
                                        selectedAmount={store.selectedMiscellanyCategories ? store.selectedMiscellanyCategories.find(t => t.id === el.id)?.amount : 0}
                                        isSelectable={false}
                                    />
                                ))
                            }
                        </div>
                        <div>
                            <ObservationsInput
                                className={classes.description_container}
                                lines={10}
                                label={'Descripción'}
                                value={store.description}
                                onChange={onDescriptionChange}/>
                        </div>
                    </Box>
                </>
            )}
        </Observer>
    )
}

export default DescriptionSelect;