import React, { useEffect } from 'react';
import {Observer} from "mobx-react-lite";
import ImageCardSkeletonSkeleton from '../../../../ui_components/Skeletons/ImageCardSkeleton/ImageCardSkeleton';
import SkeletonGrid from '../../../../ui_components/SkeletonGrid/SkeletonGrid';
import { Box } from '@material-ui/core';
import Alignment from '../../../../ui_components/common/enums/Alignment';
import { useI18nContext } from '../../../../contexts/I18nContext';
import MessageCard from '../../../../ui_components/MessageCard/MessageCard';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MiscellanyCategorySelectProperties from './MiscellanyCategorySelectProperties';
import { useStyles } from './MiscellanyCategorySelect.style';
import FilterInput from '../../../Inputs/FilterInput/FilterInput';
import ShowMiscellanyOrderCategoryItem from '../../../Info/ShowMiscellanyOrderCategoryItem/ShowMiscellanyOrderCategoryItem';

const MiscellanyCategorySelect: React.FC<MiscellanyCategorySelectProperties> = ({
                                                                          store
                                                                      }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    useEffect(() => {
        store.searchMiscellanyCategories();
    }, [store]);

    const onAmountSelect = (miscellanyCategoryId: string, amount: number) => {
        store.miscellanyCategoryAmountChange(miscellanyCategoryId, amount);
    }

    let timeout: NodeJS.Timeout;
    const onFilterChange = (value: string) => {
        clearTimeout(timeout);
        store.miscellanyCategoryFilter = value;
        const changeFilter = () => {
            store.searchMiscellanyCategories();
        };
        timeout = setTimeout(changeFilter, 800);
    }

    const skeletons = [
        <ImageCardSkeletonSkeleton></ImageCardSkeletonSkeleton>,
        <ImageCardSkeletonSkeleton></ImageCardSkeletonSkeleton>,
        <ImageCardSkeletonSkeleton></ImageCardSkeletonSkeleton>
    ];

    return (
        <Observer>
            {() => (
                <>
                    <Box className={classes.container}>
                        <Box className={classes.filter_container}
                            justifyContent={Alignment.SPACE_BETWEEN}>
                            <div className={classes.shopping_cart_container}>
                                <div className={classes.shopping_cart_icon_container}><ShoppingCartIcon/></div>
                                {store.selectedMiscellanyCategories && store.selectedMiscellanyCategories.length > 0 && <ul>
                                    {store.selectedMiscellanyCategories.map((selectedProduct, i) => (
                                        <li>
                                            {selectedProduct.name} ({selectedProduct.amount})
                                        </li>
                                    ))}
                                </ul>}
                                {(!store.selectedMiscellanyCategories || store.selectedMiscellanyCategories.length === 0) &&
                                <div className={classes.with_cart_items_text}>Sin categorias seleccionadas. Seleccione categorias en el área a la derecha para adicionarlas al carrito.</div>}
                            </div>
                        </Box>
                        <div className={classes.products_container}>
                            <div className={classes.filter_products_container}>
                                <FilterInput value={store.miscellanyCategoryFilter}
                                        placeholder={'Buscar categorias'}
                                        onChange={onFilterChange}/>
                            </div>
                            <div className={classes.category_container}>
                                {(!store.miscellanyCategories || store.miscellanyCategories.length === 0) && !store.loadingMiscellanyCategories &&
                                    <MessageCard icon={FastfoodIcon} title={i18n.translate("SELECT_A_FILTER_TO_SHOW_ARTICLES")}
                                                subtitle={''}/>}
                                {store.loadingMiscellanyCategories && <div><SkeletonGrid skeletons={skeletons}></SkeletonGrid></div>}
                                {!store.loadingMiscellanyCategories && store.miscellanyCategories &&
                                    <>
                                        

                                        {store.miscellanyCategories.map((el, i) => (
                                            <ShowMiscellanyOrderCategoryItem
                                                    key={i}
                                                    id={el.id}
                                                    title={el.name}
                                                    text={el.description}
                                                    price={el.price}
                                                    selectedAmount={store.selectedMiscellanyCategories ? store.selectedMiscellanyCategories.find(t => t.id === el.id)?.amount : 0}
                                                    isSelectable={true}
                                                    onAmountSelect={(amount) => onAmountSelect(el.id, amount)}
                                                />
                                            ))
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </Box>
                </>
            )}
        </Observer>
    )
}

export default MiscellanyCategorySelect;