function isValidCardNumber(number: string | undefined): boolean {
    if (!number) return true;
    const value = number.replace(/\s/g, '');
    let cubanNumberAndTestNumbersRegex = /^(9224|9225|9235|9226|9228|9245|9227|9205|9204)/;
    return cubanNumberAndTestNumbersRegex.test(value) || value === '';
}



export module CreditCardNumberValidation {
    export function validate(number: string | undefined): boolean {
        if (!number) return true;
        return isValidCardNumber(number);
    }
}