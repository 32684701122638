import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    card_container: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    box_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item_container: {
        width: '100%'
    },
    card_content: {
        flex: '1 0 auto',
    },
    title: {
        fontWeight: 800
    },
    price: {
        marginTop: '5px'
    },
    description_container: {
        height: '90px',
        paddingBottom: '20px',
        overflow: 'hidden'
    },
    expand_button_container: {
        textAlign: 'right',
        paddingBottom: '10px'
    },
    price_container: {
    },
    amount_input: {
        marginLeft: '5px',
        width: '60px',
        textAlign: 'center',
    },
    amount_input_contaier: {
        width: '100px'
    },
    description: {
        marginBottom: '20px',
        fontSize: '14px',
        color: '#666',
    },
    amount_input_container: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
}));

export {useStyles}